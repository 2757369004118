@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
}

.content {
  display: flex;
  align-items: flex-start;

  @media (--viewportMedium) {
    align-items: center;
    justify-content: center;
  }

  @media (min-height: 600px) and (--viewportMedium) {
    height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.title {
  margin-top: 0;
  margin-bottom: 32px;

  /* Font */
  color: var(--marketplaceColor);
  text-align: center;
  text-transform: capitalize;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

/* ================ Form ================ */

.formWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  flex-direction: column;

  /* Size */
  max-width: 800px;
  width: 100%;
  height: auto;
  margin: 0 auto;

  /* Padding */
  padding: 72px 24px;

  @media (--viewportMedium) {
    padding: 72px 48px;
    margin: 24px 32px;

    /* Style */
    border: 1px solid var(--marketplaceColor);
  }

  @media (--viewportLarge) {
    margin: 24px auto;
  }
}

.form {
  /* Size */
  max-width: 530px;
  width: 100%;
  margin: 0 auto;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* ================ Errors ================ */

.error {
  margin-top: 0;
  margin-bottom: 32px;

  /* Font */
  color: var(--colorFail);

  /* Size */
  max-width: 530px;
}

.footer {
  @media (--viewportMedium) {
    display: none;
  }
}

.heroBoxes {
  grid-template-columns: 1fr !important;

  /* Buttons */
  & a {
    min-height: 90px;
    height: 90px;
  }

  /*  boxContent */
  & > div {
    & > div {
      padding: 72px 24px;
    }
  }
}

.heroBoxesModal {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: 0;

  background-color: var(--colorWhite);
  border-bottom: none;

  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 0;
    min-height: auto;
    height: auto;

    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    margin-top: 7.5vh;
    margin-bottom: 7.5vh;
  }
}
