@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.inputRoot {
  composes: authenticationInputStyles from global;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  margin-top: 16px;
  margin-bottom: 32px;
}

.recoveryLink {
  color: var(--marketplaceColor);

  /* Effect */
  opacity: 0.5;
  transition: var(--transitionStyleButton);

  &:hover {
    opacity: 1;
  }
}

.signupLinkText {
  margin-top: 32px;
  margin-bottom: 0;

  /* Font */
  text-transform: capitalize;
  color: var(--marketplaceColor);
}

.signupLink {
  text-decoration: underline;
}
